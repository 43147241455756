import clsx from "clsx"
import React from "react"

export type InputProps = {
  containerClassName?: string
  error?: string | null
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const InputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = ({containerClassName, error, className, ...props}, ref) => {
  const containerClasses = clsx("flex flex-col", containerClassName)
  const classes = clsx(
    "placeholder-ash text-steel rounded p-1",
    className,
    error
      ? "invalid:text-solarflare border-solarflare focus:border-solarflare focus:ring-solarflare"
      : "border-ash focus:border-steel focus:ring-steel",
  )

  return (
    <label className={containerClasses}>
      <input {...props} className={classes} ref={ref} />
      {!!error && (
        <span className="text-solarflare mt-[7px] ml-1">{error}</span>
      )}
    </label>
  )
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(InputRender)
